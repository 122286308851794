<template>
  <layout>
    <div class="search-top">
      <div class="contant">
        <div class="contant-top">
          <div class="contant-logo">
            <img src="@/assets/home/logo.png" alt @click="$router.push('/')"/>
            <span>爱玩就上爱租号</span>
          </div>
          <div class="contant-search">
            <input type="text" placeholder="搜你想要的~" v-model="valueList"/>
            <button @click="goSerach">搜索</button>
          </div>
        </div>
        <div class="contant-bottom">
          <div class="contant-hot">
            <img src="@/assets/home/hot.png" alt />
            <span>热门游戏</span>
            <div class="newClass">
              <hotlist></hotlist>
            </div>
          </div>


          <ul>
            <li @click="toClick(0)">首页</li>
            <li :class="{ activem: isShows == 1 }" @click="toClick(1)">
              爱租号
            </li>
            <li @click="toClick(2)">
              工具下载
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="center-top w">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>帮助中心</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="contaner">
          <div class="center-left card">
            <ul>
              <li v-for="(item,index) in helpsList" :key="index" @click="showtos(index)">{{item}}</li>       
            </ul>
          </div>
          <div class="center-right card" v-if="topath">
            <p>{{helptitle}}</p>
            <ul>
              <li @click="topaths(index,false)"  v-for="(item,index) in subHelpsList" :key="index" >{{item.title}}</li>
         
            </ul>
          </div>
          <div class="center1 card" v-else>
            <div class="btn">
              <el-button
                size="mini"
                round
                plain
                icon="el-icon-arrow-left"
                @click="topaths('',true)"
                >返回</el-button
              >
              <div class="center-p">
                <p>{{detail.title}}</p>
                <span>发布时间:{{detail.created_at}}</span>
              </div>
              <div></div>
            </div>
            <p v-html="detail.content">
           
            </p>
          </div>
        </div>
        <div class="center-bottom card">
          <p>账号租赁流程</p>
          <ul class="bottom-img">
            <li>
              <img src="../../assets/center/zcdl.png" alt="" />
              <span>注册登录</span>
            </li>
            <li><img src="../../assets/center/jt.png" alt="" /></li>
            <li>
              <img src="../../assets/center/czje.png" alt="" />
              <span>充值金额</span>
            </li>
            <li><img src="../../assets/center/jt.png" alt="" /></li>
            <li>
              <img src="../../assets/center/zhzy.png" alt="" />
              <span>选择心仪账号租用</span>
            </li>
            <li><img src="../../assets/center/jt.png" alt="" /></li>
            <li>
              <img src="../../assets/center/xdzf.png" alt="" />
              <span>下单支付</span>
            </li>
            <li><img src="../../assets/center/jt.png" alt="" /></li>
            <li>
              <img src="../../assets/center/fzjsm.png" alt="" />
              <span>获取解锁码并复制</span>
            </li>
            <li><img src="../../assets/center/jt.png" alt="" /></li>
            <li>
              <img src="../../assets/center/xzaz.png" alt="" />
              <span>下载上号器并安装</span>
            </li>
            <li><img src="../../assets/center/jt.png" alt="" /></li>
            <li>
              <img src="../../assets/center/jssh.png" alt="" />
              <span>粘贴解锁码上号</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <side-Bar></side-Bar>
  </layout>
</template>

<script>
import layout from "../../components/layout";
import sideBar from "../../components/common/sidebar";
import hotlist from '../../components/hotlist'
export default {
  components: {
    layout,
    sideBar,
    hotlist
  },
  data() {
    return {
		label:1,
		isShows: 1,
		topath: true,
		valueList:'',
		helptitle:'常见问题',
		helpsList:[],
		subHelpsList:[],
		detail:[],
    };
  },
  created () {
    this.getCenter()
  
  },
  mounted() {
  },
  methods: {
		getCenter () {
			this.$get('api/helps', {cata:1}).then(res => {
				if (res.code == 0) {
					this.helpsList = res.ret
					this.getSubCenter()
				}
			})
		},
		showtos(index){
			if(index==this.label){
				return
      }
      this.topath = true
			this.label = index
			this.helptitle = this.helpsList[index]
			this.getSubCenter()
		},
		getSubCenter () {
			this.$get('api/helps', {label:this.label}).then(res => {
				if (res.code == 0) {
					this.subHelpsList = res.ret
				}
			})
		},
		topaths(subindex,status){
			this.topath = status
			if(!status){
				this.detail = this.subHelpsList[subindex]
			}
		},
      toClick(id) {
      if (id == 0) {
        this.$router.push({
          path: "/home",
        });
      } else if (id == 2) {
        this.$router.push({
          path: "/download",
        });
      } else {
        this.$router.push({
          path: "/lobby",
        });
      }
      this.isShow = id;
    },
    goSerach() {
        if(this.valueList){
            this.$router.push({
            path: '/lobby',
            query: {
            key_word: this.valueList
            }
          })
        }
      
    },
  },
};
</script>

<style lang='scss' scoped>
.search-top {
  width: 100%;
  background-color: #fff;
  height: 168px;
  .contant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .contant-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 40px;
      height: 86px;
      .contant-logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 218px;
          height: 55px;
          margin-right: 18px;
        }
        span {
          font-size: 15px;
        }
      }
      .contant-search {
        width: 480px;
        height: 48px;
        background: #ffffff;
        border: 2px solid #3c7efe;
        border-right: none;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline-style: none;
          border-radius: 24px;
          text-indent: 2em;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a6b8;
        }
        button {
          position: relative;
          width: 120px;
          height: 48px;
          background: #3c7efe;
          border-radius: 24px;
          color: #ffffff;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: -2px;
          padding-left: 20px;
          cursor: pointer;
        }
        button::after {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../assets/home/ss.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .contant-bottom {
      display: flex;
      .contant-hot {
        width: 200px;
        height: 42px;
        border-radius: 12px 12px 0 0;
        background-color: #3c7efe;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        &:hover .newClass{
          max-height: 460px;
          transition: max-height ease-in .3s;
        }

        img {
          width: 16px;
          height: 18px;
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          color: #ffffff;
        }

        .newClass{
          position: absolute;
          top: 42px;
          left: 0;
          max-height: 0;
          min-height: 0;
          overflow: hidden;
          transition: max-height ease-out .4s;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #666;
          padding: 0 30px;
          // height: 100%;
          line-height: 17px;
          position: relative;
          cursor: pointer;
        }
        .activem{
          color: #000;
          font-weight: bold;
        }
        .activem:after {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -7px;
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background-color: #3b7dfd;
        }
      }
    }
  }
}
.center {
  width: 100%;
  padding-bottom: 66px;
  background-color: #f0f2f5;
  .center-top {
    .el-breadcrumb {
      padding: 25px 0;
    }
    .contaner {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .center-left {
        width: 220px;
        height: 620px;
        ul {
          margin-top: 32px;
          li {
            text-align: center;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #83899d;
            line-height: 48px;
            cursor: pointer;
            &:hover{
              font-size: 20px;
              font-weight:bold;
              color: #000;
            }
          }
        }
      }
      .center-right {
        width: 968px;
        height: 620px;
        padding: 33px 61px;
        p {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          margin-bottom: 40px;
        }
        ul {
          li {
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #83899d;
            line-height: 30px;
            cursor: pointer;
            &:hover{
               color: #3b7dfd;
            }
          }
        }
      }
      .center1 {
        width: 968px;
        min-height: 620px;
        padding:30px 67px;
        .btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px 0;
          border-bottom: 1px solid #f6f7fa;
          margin-bottom: 30px;
          .center-p {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 100px;
            p {
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            }
            span {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #a1a6b7;
              line-height: 36px;
            }
          }
        }
        p {
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #83899d;
        }
      }
    }

    .center-bottom {
      width: 100%;
      height: 320px;
      padding: 0 56px;
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        margin: 37px 0 74px;
      }
      .bottom-img {
        display: flex;
        justify-content: space-between;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          &:nth-child(2n) {
            margin-top: 30px;
          }
          span {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #83899d;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
//
.w {
  width: 1200px;
  margin: 0 auto;
}

.card {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
}

</style>